export const useSetScreenValues = () => {
  const windowStore = useWindowStore()

  const handleResize = () => {
    windowStore.$patch({ windowWidth: window.innerWidth })
  }

  const handleScrollChange = () => {
    windowStore.$patch({ windowScrollY: window.scrollY })
  }

  onBeforeMount(() => {
    handleResize()
    handleScrollChange()
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScrollChange)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
    window.removeEventListener('scroll', handleScrollChange)
  })
}

const useResize = () => {
  useSetScreenValues()
  const windowStore = useWindowStore()
  const windowWidth = computed(() => windowStore.windowWidth)
  const windowScrollY = computed(() => windowStore.windowScrollY)

  return {
    windowWidth,
    windowScrollY,
  }
}
export default useResize
